import React from 'react'
import LOGO from "../../Images/Logo/project logo.png"
const Section2 = ({ handleShow }) => {
  return (
    <div id='overview' className='section-2 py-5'>
      <div className="my-5 px-2 px-md-5 d-flex justify-content-center flex-wrap">
        <div className="col-11 d-flex align-items-center justify-content-center col-sm-5 col-md-4 mb-5 mb-sm-0">
          <img style={{maxHeight:"350px"}} src={LOGO} alt="logo" />
        </div>
        <div className="col-11 col-sm-7 col-md-8">
          <h5 className='mb-4'>
            High Cliff at a Glance :<span> Walk to Work, Dive into Luxury.</span>
          </h5>
          <p>
            Nestled in the vibrant heart of <b> East Bangalore, High Cliff</b> stands as an embodiment of luxury and unparalleled convenience. Every facet of this premier project is carefully crafted to ensure it is nothing short of excellence. With proximity to the city's major tech parks and business hubs, <b> it's more than just a home - it's a statement.</b>
          </p>
          <p>
            Combining modern architecture with sustainable practices, <b>High Cliff</b> is an <b>IGBC pre-certified</b> project, offering <b> state-of-the-art amenities</b> for its residents. Expertly designed by <b> top-notch consultants</b>, every element, from rainwater harvesting systems endorsed by renowned environmentalists to opulent finishes, resonates with quality and style.
          </p>
          <p>
            <b>High Cliff</b> offers you the unique opportunity to be in the midst of the bustling city while enjoying the serene comforts of a <b>premium residence</b>. Be part of a community that values both, the good life and the environment.
          </p>
          <p><b>Discover High Cliff - where dreams find their home, and every day is a testament to luxury, comfort, convenience, and sustainable living
          </b></p>
          <button onClick={handleShow} className="btn-yellow mt-4">
            Enquire Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default Section2