import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Section1 from './HomeSection/Section1'
import FormModal from './FormModal'
import { ToastContainer } from 'react-toastify'
import Section2 from './HomeSection/Section2'
import Section11 from './HomeSection/Section11'
import Section3 from './HomeSection/Section3'
import Section4 from './HomeSection/Section4'
import Section5 from './HomeSection/Section5'
import Section6 from './HomeSection/Section6'
import Section7 from './HomeSection/Section7'
import Section8 from './HomeSection/Section8'
import Section9 from './HomeSection/Section9'
import Section10 from './HomeSection/Section10'
import Section12 from './HomeSection/Section12'
import Section13 from './HomeSection/Section13'

const Home = () => {
   const [show, setShow] = useState(false);
   const handleShow = () => {
      setShow(true)
   }
   return (
      <>
         <ToastContainer />
         <FormModal show={show} setShow={setShow} />
         <Header />
         <Section1 />
         <Section2 handleShow={handleShow} />
         <Section3 />
         <Section12 />
         <Section4 />
         <Section5 handleShow={handleShow} />
         <Section7 />
         <Section8 />
         <Section6 />
         <Section9 />
         <Section10 />
         <Section13 />
         <Section11 />
         <Footer />
      </>

   )
}

export default Home