import React from 'react'

const Section3 = () => {
   return (
      <>

         <div className='section-3 py-5 px-2 px-md-5'>
            <div className="py-5">

               <div className='d-flex justify-content-center mb-5  gap-5'>
                  <div className="card-overview">
                     <h5>1.4 Acres</h5>
                     <p>Land Area</p>
                  </div>
                  <div className="card-overview">
                     <h5>2, 2.5, 3 BHK</h5>
                     <p>Configuration</p>
                  </div>
               </div>
               <div className='d-flex justify-content-center gap-5'>
                  <div className="card-overview">
                     <h5>150</h5>
                     <p>No. of Units</p>
                  </div>
                  <div className="card-overview">
                     <h5>997-1293 sqft</h5>
                     <p>Size</p>
                  </div>
               </div>
            </div>
         </div>
         <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
         </div>
      </>
   )
}

export default Section3