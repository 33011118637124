import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import Logo from "../Images/Logo/project logo.png"
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    configuration: "",
  });
  const [value, setValue] = useState()
  console.log(form)

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (value.length < 9) {
      toast.error("Please Enter a valid Phone Number")
      return;
    }
    setLoading(true)
    const data = {
      email: form.email,
      name: form.name,
      phone: value,
      configuration: form.configuration
    }
    try {
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addCliffEnquiry",
        data
      );
      console.log("Enquiry submitted:", response.data);
      setForm({
        email: "",
        name: "",
        phone: "",
        configuration: "",
      });
      setValue()
      navigate('/thank-you')
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false)
  };
  console.log(value)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="col-12" id="contained-modal-title-vcenter modal-title">
          <img style={{ height: "70px" }} src={Logo} className="logo-img-modal" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mx-3 modal-form" onSubmit={handleFormSubmit}>

          <div className="row ">
            <div className="col-12 my-3">
              <input
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Name"
                value={form.name}
                onChange={handleFormChange}
                name="name"
              />
            </div>
            <div className="col-12">
              <input
                className="input w-100 px-2"
                type="email"
                required
                placeholder="Email"
                value={form.email}
                onChange={handleFormChange}
                name="email"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <PhoneInput
                required
                className="input w-100 phone-input"
                inputProps={{
                  name: 'phone',
                  required: true,
                  // autoFocus: true
                }}
                country={'in'}
                value={value}
                onChange={(e) => setValue(e)}
              // onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <select
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Enter a message.."
                // value={form.message}
                onChange={handleFormChange}
                name="configuration"
              >
                <option selected disabled value="">Select Configuration</option>
                <option value="2 BHK">2 BHK</option>
                <option value="2.5 BHK">2.5 BHK</option>
                <option value="3 BHK">3 BHK</option>
              </select>
            </div>
          </div>
          <hr />
          <div
            className="d-flex justify-content-center modal-cont-ftr"
            type="submit"
          >
            <button disabled={loading} className="btn-yellow btn-black text-center modal-btn">
              {loading ? ("Loading...") : ("Submit")}
            </button>
          </div>
        </Form>
      </Modal.Body>

    </Modal>
  );
}

const FormModal = ({ show, setShow }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default FormModal;
