import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import img1 from "../../Images/Amenities/Barbell.png"
import img2 from "../../Images/Amenities/Billiards.png"
import img3 from "../../Images/Amenities/Business Building.png"
import img4 from "../../Images/Amenities/Day Care.png"
import img5 from "../../Images/Amenities/Disco Ball.png"
import img6 from "../../Images/Amenities/Garage.png"
import img7 from "../../Images/Amenities/Garden.png"
import img8 from "../../Images/Amenities/House With a Garden.png"
import img9 from "../../Images/Amenities/Lap Pool.png"
import img10 from "../../Images/Amenities/Meditation.png"
import img11 from "../../Images/Amenities/Playground.png"
import img12 from "../../Images/Amenities/Spa.png"
import img13 from "../../Images/Amenities/Table Tennis.png"
import img14 from "../../Images/Amenities/Timezone +8.png"


const Section7 = () => {
   return (
      <div id='amenities'  className='section-7 px-2 px-md-5 py-5'>
         <h5 className='  text-center mt-3'>
            Crafted Comforts : <span> Dive into Our Exclusive Amenities.
            </span>
         </h5>
         <div className='px-0 px-lg-5'>

            <div className="boxes-usps d-flex flex-wrap justify-content-center pt-5 px-0 px-md-5">
               <div className="usp">
                  <img src={img5} alt="icon" />
                  <p>ClubHouse</p>
               </div>
               <div className="usp">
                  <img src={img1} alt="icon" />

                  <p>Gym </p>
               </div>
               <div className="usp">
                  <img src={img7} alt="icon" />

                  <p>Landscaped Gardens</p>
               </div>
               <div className="usp">
                  <img src={img9} alt="icon" />

                  <p>Swimming Pool </p>
               </div>
               <div className="usp">
                  <img src={img3} alt="icon" />

                  <p>Business Center</p>
               </div>
               <div className="usp">
                  <img src={img6} alt="icon" />

                  <p>Covered Car Parking </p>
               </div>
               <div className="usp">
                  <img src={img2} alt="icon" />

                  <p>Billiards </p>
               </div>
               <div className="usp">
                  <img src={img4} alt="icon" />

                  <p>Day Care</p>
               </div>
               <div className="usp">
                  <img src={img10} alt="icon" />

                  <p>Meditation Hall
                  </p>
               </div>
               <div className="usp">
                  <img src={img11} alt="icon" />

                  <p>Play Area</p>
               </div>
               <div className="usp">
                  <img src={img14} alt="icon" />

                  <p>Snooker</p>
               </div>
               <div className="usp">
                  <img src={img12} alt="icon" />

                  <p>Spa</p>
               </div>
               <div className="usp">
                  <img src={img13} alt="icon" />

                  <p>Table Tennis</p>
               </div>
               <div className="usp">
                  <img src={img8} alt="icon" />

                  <p>Terrace Garden</p>
               </div>
            </div>

            <p className="text-yellow mt-3">
               And Many More.....
            </p>
         </div>
      </div>
   )
}

export default Section7