import React from 'react'
import { Table } from 'react-bootstrap'
import { BiSolidLockAlt } from "react-icons/bi"

const Section5 = ({ handleShow }) => {
   return (
      <div id="configuration" className='py-5 d-flex flex-column align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-5 '>
         <h5 className='pb-5 mb-0 text-center'>
            Embrace <span>
                Limitless Space
            </span>
         </h5>

         <div className="col-12 col-sm-9  col-xl-8  m-auto price-box">
            <Table className='mb-5' striped bordered hover>
               <thead className='thead'>
                  <tr>
                     <th>Configuration</th>
                     <th>Sizes</th>
                     <th>Price</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td><p className='mb-0'>2 BHK</p></td>
                     <td><p className='mb-0'>997-1251  sqft</p></td>
                     <td><div onClick={handleShow} className='get-price-btn'><BiSolidLockAlt />Unlock Price </div></td>
                  </tr>
                  <tr>
                     <td><p className='mb-0'>2.5 BHK</p></td>
                     <td><p className='mb-0'>1355-1574 sqft</p></td>
                     <td ><div onClick={handleShow} className='get-price-btn'><BiSolidLockAlt />Unlock Price</div></td>
                  </tr>
                  <tr>
                     <td><p className='mb-0'>3 BHK</p></td>
                     <td><p className='mb-0'>1715-1793 sqft</p></td>
                     <td ><div onClick={handleShow} className='get-price-btn'><BiSolidLockAlt />Unlock Price</div></td>
                  </tr>
               </tbody>
            </Table>
         </div>
      </div>
   )
}

export default Section5