import React from 'react'
import img1 from "../../Images/Section4/1 (1).webp"
import img2 from "../../Images/Section4/2 (1).webp"
import img3 from "../../Images/Section4/3 (1).webp"
const Section4 = () => {
   return (
      <div className='section-4'>
         <div className='img-text-section d-flex flex-wrap-reverse'>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5">
               <h5 className='mb-4'>Rise to Luxury
               </h5>
               <p><span>Elevate Your Everyday : </span>Elevate your lifestyle with High Cliff's opulence and luxury . Every unit is an epitome of elegance, designed with meticulous attention to detail and adorned with modern amenities. The beautiful views of the city, coupled with the serene ambiance, provide an unmatched living experience. It's not just about reaching new heights; it's about experiencing luxury in every corner.
               </p>
            </div>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img1} alt="" />
            </div>
         </div>
         <div className='img-text-section d-flex flex-wrap'>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img2} alt="" />
            </div>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5 ">
               <h5 className='mb-4'>Prime Location
               </h5>
               <p><span>The Heartbeat of East Bangalore : </span>Strategically positioned on Panathur Main Road, a mere 400 meters off the ORR, High Cliff stands as a beacon of unparalleled connectivity. Tech parks, bustling business hubs, and enticing leisure spaces lie within a whisper's distance — truly just a stone's throw away. <span> In a city where every moment counts, the luxury of saved commute time is priceless.</span> At High Cliff, you're not simply investing in a residence; you're syncing with the very heartbeat of the city.</p>
            </div>

         </div>
         <div className='img-text-section d-flex flex-wrap-reverse'>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5">
               <h5 className='mb-4'>Eco-Conscious Living
               </h5>
               <p><span>Embracing the Future, Sustainably : </span>At High Cliff, our commitment to sustainable living is evident in every brick laid - quite literally. We've chosen Porotherm bricks for construction, known for their thermal efficiency, eco-friendliness, and superior quality. Combined with our top-tier rainwater harvesting systems and eco-friendly designs, High Cliff is a testament to responsible luxury. Our IGBC pre-certified project ensures that while you indulge in the finest comforts, you also tread lightly on the environment. Live responsibly; live opulently.</p>
            </div>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img3} alt="" />
            </div>
         </div>
      </div>
   )
}

export default Section4