import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import img1 from "../../Images/Section6/Diamond.png"
import img2 from "../../Images/Section6/Living Room.png"
import img3 from "../../Images/Section6/Place Marker.png"
import img4 from "../../Images/Section6/Shopping Bag Full.png"
import img5 from "../../Images/Section6/Sustainability.png"

const Section6 = () => {
   return (
      <div id='usps' className='section-6 px-2 px-md-5 py-5'>
         <div>
            <h5 className='  text-center mt-3'>

               Standout in Every Sense : <span>
                  Our Top Features
               </span>
            </h5>

            <div className="boxes-amenities d-flex flex-wrap justify-content-center pt-5 px-0 px-xl-5">
               <div className="box-aminity mb-5 col-12 col-md-6 col-xl-4 px-4">
                  <div className="box-in ">

                     <div className="logo-icon my-3">
                        <img src={img3} alt="logo" />
                     </div>
                     <h5 className='mb-3'>Prime Location</h5>
                     <p className='text-center'>Nestled in the heart of East Bangalore, High Cliff offers an enviable address that brings convenience and accessibility right to your doorstep.</p>
                  </div>
               </div>
               <div className="box-aminity mb-5 col-12 col-md-6 col-xl-4 px-4">
                  <div className="box-in ">

                     <div className="logo-icon my-3">
                     <img src={img1} alt="logo" />
                     </div>
                     <h5 className='mb-3'>Unparalleled Luxury</h5>
                     <p className='text-center'>Experience the epitome of luxury living at High Cliff, where every detail is meticulously designed to provide a lavish and sophisticated lifestyle.</p>
                  </div>
               </div>
               <div className="box-aminity mb-5 col-12 col-md-6 col-xl-4 px-4">
                  <div className="box-in ">

                     <div className="logo-icon my-3">
                     <img src={img2} alt="logo" />
                     </div>
                     <h5 className='mb-3'>Elevated Living</h5>
                     <p className='text-center'>Rise above the ordinary with our high-rise residential towers, offering breathtaking panoramic views of the cityscape and a sense of exclusivity.</p>
                  </div>
               </div>
               <div className="box-aminity mb-5 col-12 col-md-6 col-xl-4 px-4">
                  <div className="box-in ">

                     <div className="logo-icon my-3">
                     <img src={img5} alt="logo" />
                     </div>
                     <h5 className='mb-3 text-center'>Lifestyle Beyond Compare</h5>
                     <p className='text-center'>Experience unmatched luxury and leisure at High Cliff. Enjoy amenities like a business center, spa, gym, and lush gardens for a truly indulgent lifestyle.</p>
                  </div>
               </div>
               <div className="box-aminity mb-5 col-12 col-md-6 col-xl-4 px-4">
                  <div className="box-in ">

                     <div className="logo-icon my-3"> <img src={img4} alt="logo" />
                     </div>
                     <h5 className='mb-3'>Sustainable Living</h5>
                     <p className='text-center'>High Cliff isn't just a luxury residence; it's a pledge to a greener future. Live eco-consciously in our green building, blending sustainability with style and comfort.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Section6